<template>
<div class="forms">
    <h2 class="title1">Merchant</h2>
    <div class="form-grids row widget-shadow" data-example-id="basic-forms">
      <div class="form-title">
        <h4 v-if="!update">Add New Merchant</h4>
        <h4 v-if="update">Update Merchant</h4>
        <span class="text-danger" v-if="error">{{error}}</span>
        <span class="text-success" v-if="success">{{success}}</span>
      </div>
      <div class="form-body">
        <form method="post" @submit.prevent="saveMerchant" autocomplete="off">
          <div class="form-group col-md-6">
            <label for="status">Status</label>
            <select class="form-control" id="status" name="status" v-model="merchant.status">
              <option value="0">Deactive</option>
              <option value="1">Active</option>
            </select>
          </div>
          <div class="form-group col-md-6">
            <label for="username">Username</label>
            <input type="text" class="form-control" id="username" name="username" placeholder="Username" v-model="merchant.username">
            <span class="text-danger" v-if="$v.merchant.username.$error">This field is required</span>
          </div>
          <div class="form-group col-md-6">
            <label for="email">Email</label>
            <input type="text" class="form-control" id="email" name="email" placeholder="Email" v-model="merchant.email">
            <span class="text-danger" v-if="$v.merchant.email.$error">This field is required</span>
          </div>
          <div class="form-group col-md-4">
            <label for="phone">Phone</label>
            <input type="text" class="form-control" id="phone" name="phone" placeholder="Mobile" v-model="merchant.phone">
            <span class="text-danger" v-if="$v.merchant.phone.$error">This field is required</span>
          </div>
          <div class="form-group col-md-2">
            <label for="commission">Commission</label>
            <input type="number" step="any" class="form-control" id="commission" name="commission" placeholder="Commission" v-model="merchant.commission">
            <span class="text-danger" v-if="$v.merchant.commission.$error">This field is required</span>
          </div>
          <div class="form-group col-md-3">
            <label for="password">Password</label>
            <input type="password" class="form-control" id="password" name="password" placeholder="Password" v-model="merchant.password">
            <span class="text-danger" v-if="$v.merchant.password.$error">This field is required</span>
          </div>
          <div class="form-group col-md-3 checkbox">
            <label class="col-form-label"><input type="checkbox" v-model="merchant.isForcePasswordChange"> Is Force Change Password</label>
          </div>
          <div class="form-group col-md-3" v-if="!update">
            <label  class="col-form-label">Security Question</label>
            <select  formcontrolname="security_question" class="form-control" v-model="merchant.security_question">
              <option value="">Select Your Security Question</option>
              <option value="In what city or town was your first job?">In what city or town was your first job?</option>
              <option value="What is your favorite city name?">What is your favorite city name?</option>
            </select>
            <span class="text-danger" v-if="$v.merchant.security_question.$error">This field is required</span>
          </div>
          <div class="form-group col-md-3" v-if="!update">
            <label for="answer">Answer</label>
            <input type="text" class="form-control" id="answer" name="answer" placeholder="Answer" v-model="merchant.answer">
            <span class="text-danger" v-if="$v.merchant.answer.$error">This field is required</span>
          </div>
          <div class="row col-md-12">
            <h4>Devices</h4>
            <table class="table">
              <thead>
              <tr>
                <th>Device Name</th>
                <th>Device Code</th>
                <th>Device Location</th>
                <th width="10%"><button type="button" class="btn btn-sm btn-success" @click="add_remove_device(1)"><i class="fa fa-plus"></i> </button> </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(device, index) in merchant.deviceIds" :key="index">
                <td>
                  <select class="form-control" v-model="merchant.deviceIds[index].device_name" @change="changeDevice($event,index)">
                    <option value="">-- Select Device --</option>
                    <option v-for="cdevice in devices" :key="cdevice.id" :value="cdevice.name">{{cdevice.name}}</option>
                  </select>
                  <span class="text-danger" v-if="$v.merchant.deviceIds.$each.$iter[index].device_name.$error">This field is required</span>
                </td>
                <td>
                  <input class="form-control" readonly v-model="merchant.deviceIds[index].device_code">
                </td>
                <td>
                  <input class="form-control" readonly v-model="merchant.deviceIds[index].device_loc">
                </td>
                <td>
                  <button type="button" class="btn btn-sm btn-danger" @click="add_remove_device(0,index)"><i class="fa fa-minus"></i> </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-12">
          <button type="submit" class="btn btn-default">Submit</button>
          </div>
        </form>
      </div>
    </div>
</div>
</template>
<script>
import db from '../db';
import {required} from "vuelidate/lib/validators";
export default {
  data(){
    return {
      update:false,
      olddevices:[],
      success:"",
      error:"",
      devices:[],
      merchant:{
        username:'',
        userType:"merchant",
        status:"0",
        security_question:"",
        answer:"",
        phone_provider:"MTN",
        phone:"",
        password:"",
        locationId:"",
        isForcePasswordChange:false,
        isAdmin:false,
        email:"",
        commission:"0",
        deviceIds:[{
          device_id:"",
          device_loc:"",
          device_name:"",
          device_code: ""
        }]
      }
    }
  },
  validations: {
    merchant: {
      username:{required},
      security_question:{required},
      answer:{required},
      phone:{required},
      password:{required},
      email:{required},
      commission:{required},
      deviceIds: {
        $each: {
          device_name: {required}
        }
      }
    }
  },
  beforeMount() {
    if(this.$route.params.id) {
      this.update = true;
      let id = this.$route.params.id;
      db.collection("merchants").doc(id).get().then((doc)=>{
        this.merchant = doc.data();
        this.merchant.deviceIds.map((md)=>{
          this.olddevices.push(md.device_id);
          this.devices.push({
            id:md.device_id,
            name:md.device_name,
            location:md.device_loc,
            code:md.device_code,
          });
        })
      });
    }
    db.collection("poses").where("status","==","1").get().then((snapshort)=>{
      snapshort.docs.map((doc)=>{
        let data = doc.data();
        this.devices.push({
          id:doc.id,
          name:data.name,
          location:data.location,
          code: data.code
        });
      });
    }).catch((error) => {
      console.log("Error getting documents: ", error);
    });
  },
  methods:{
    saveMerchant(){
      this.$v.merchant.$touch();
      if(this.$v.merchant.$error) return
      if(!this.update) {
        db.collection("merchants").add(this.merchant).then((res) => {
          this.merchant.deviceIds.map((md) => {
            db.collection("poses").doc(md.device_id).update({"status": "2","merchant_id":res.id});
          });
          this.success = "Data has been successfully saved.";
          this.$router.replace("/merchants");
        }).catch((error) => {
          this.error = error;
        });
      }else{
        db.collection("merchants").doc(this.$route.params.id).update(this.merchant).then(() => {
          this.merchant.deviceIds.map((md) => {
            if(this.olddevices.indexOf(md.device_id) != -1){
              this.olddevices.splice(this.olddevices.indexOf(md.device_id),1);
            }
            db.collection("poses").doc(md.device_id).update({"status": "2","merchant_id":this.$route.params.id});
          });
          if(this.olddevices.length>0){
            this.olddevices.map((id) => {
              db.collection("poses").doc(id).update({"status": "1","merchant_id":""});
            });
          }
          this.success = "Data has been successfully updated.";
          this.$router.replace("/merchants");
        }).catch((error) => {
          this.error = error;
        });
      }
    },
    changeDevice(event,index){
      let value = event.target.value;
      this.devices.map((d)=>{
        if(d.name == value){
          this.merchant.deviceIds[index].device_code = d.code;
          this.merchant.deviceIds[index].device_id = d.id;
          this.merchant.deviceIds[index].device_loc = d.location;
        }
      })
    },
    add_remove_device(key,index){
      if(key== 1){
        this.merchant.deviceIds.push({
          device_id:"",
          device_loc:"",
          device_name:"",
          device_code:""
        })
      }
      if(key == 0){
        this.merchant.deviceIds.splice(index,1);
      }
    }
  }
}
</script>