import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/components/Login";
import Layout from "@/components/Layout";
import Merchants from "@/views/Merchants";
import Dashboard from "@/views/Dashboard";
import Transaction from "@/views/Transaction";
import AddMerchant from "@/views/AddMerchant";
import Inventory from "@/views/Inventory";
import AddInventory from "@/views/AddInventory";
import DownloadReport from "@/views/DownloadReport";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Layout,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: '/merchants',
        name: 'Merchants',
        component: Merchants
      },
      {
        path: '/merchant',
        name: 'Merchant_new',
        component: AddMerchant
      },
      {
        path: '/merchants/:id',
        name: 'Merchant_edit',
        component: AddMerchant
      },
      {
        path: '/merchant-profile/:id',
        name: 'Merchant_view',
        component: MerchantProfile
      },
      {
        path: '/inventory-list',
        name: 'inventory',
        component: Inventory
      },
      {
        path: '/inventory',
        name: 'Inventory_new',
        component: AddInventory
      },
      {
        path: '/inventory/:id',
        name: 'Inventory_edit',
        component: AddInventory
      },
      {
        path: '/reports',
        name: 'Report',
        component: DownloadReport
      },
      {
        path: '/profile',
        name: 'Profile',
        component: Profile
      },
      {
        path: '/transaction-reverse',
        name: 'Transactions',
        component: Transaction
      },
    ]
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
import config from "../config"
import Profile from "@/views/Profile";
import MerchantProfile from "@/views/MerchantProfile";
router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !config.isAuthenticated()) next({ name: 'Login' })
  else if (to.name == 'Login' && config.isAuthenticated()) next({ name: 'Dashboard' })
  else next()
})

export default router
