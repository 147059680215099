<template>
<div class="row">
  <my-table v-if="table_load"
            :tableaction="false"
            page_title="Download Reports"
            :headers="headers"
            :items="transactions"
            :buttons="true"
            ></my-table>
</div>
</template>

<script>
import db from "@/db";
export default {
  data(){
    return {
      table_load:false,
      headers:[
        {text:"Merchant Name",value:"username"},
        {text:"Email",value:"email"},
        {text:"Amount",value:"amount"},
        {text:"Commission",value:"commission"},
        {text:"Commission Amount",value:"commission_amount"},
        {text:"Payabale Amount",value:"subamount"},
        {text:"Transaction ID",value:"trxId"},
        {text:"Transaction Date",value:"trxDate"},
        {text:"Transaction Status",value:"trxStatus"},
      ],
      transactions:[]
    }
  },
  beforeMount() {
    let count=0;
    db.collection("Transaction").get().then((trasnap)=>{
      trasnap.forEach((t)=>{
        let data = t.data();
        data.id = t.id;
        data.username ='';
        data.commission = 0;
        data.commission_amount = 0;
        data.subamount = 0;
        db.collection("merchants").where("email","==",data.email).get().then((mersnap)=>{
         mersnap.forEach((mer)=> {
           let tt = mer.data();
           if(mer.data()) {
             data.username = tt.username;
             data.commission = tt.commission;
             data.commission_amount = this.getCommission(data.amount,data.commission);
             data.subamount = data.amount - this.getCommission(data.amount,data.commission);
             console.log(count+"/"+trasnap.docs.length)
             if(count == trasnap.docs.length){
               this.table_load = true;
             }
           }else {
             data.merchant.commission=0;
             data.merchant.commission_amount=0;
             data.merchant.subamount=0;
           }
         })
        })
         this.transactions.push(data);
        count++;
      });
    })
  },
  methods:{
    getCommission(commission,amount){
      let comm =(parseFloat(amount)*parseFloat(commission))/100
      return (comm || 0).toFixed(2);
    }
  }
}
</script>