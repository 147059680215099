<template>
  <div class="forms">
    <h2 class="title1">Merchant</h2>
    <div class="form-grids row widget-shadow" data-example-id="basic-forms">
      <div class="form-title">
        <h4>Merchant Details #{{merchant.username}}</h4>
      </div>
      <div class="form-body">
        <table class="table table-striped">
          <tr>
            <th>Username</th>
            <td>{{merchant.username}}</td>
            <th>Email</th>
            <td>{{merchant.email}}</td>
            <th>Mobile</th>
            <td>{{merchant.phone}}</td>
          </tr>
          <tr>
            <th>Security Question</th>
            <td>{{merchant.security_question}}</td>
            <th>Answer</th>
            <td>{{merchant.answer}}</td>
            <th>Status</th>
            <td>{{merchant.status=='1'?"Activated":"Deactivate"}}</td>
            <th>Commission</th>
            <td>{{merchant.commission}}</td>
          </tr>
        </table>
        <h4>Devices</h4>
        <table class="table table-striped">
          <thead>
          <tr>
            <th>Device Name</th>
            <th>Device Code</th>
            <th>Device Location</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="device in merchant.deviceIds" :key="device.device_id">
            <td>{{device.device_name}}</td>
            <td>{{device.device_code}}</td>
            <td>{{device.device_loc}}</td>
          </tr>
          </tbody>
        </table>
        <my-table v-if="load_pos_table"
                  :tableaction="false"
                  page_title="Transactions"
                  :headers="headers"
                  :items="transactions"
                  :buttons="true"
                  :overwrite_default_action="true"
                  :show_on_merchant_profile="true"
        >
        </my-table>
      </div>
    </div>
  </div>
</template>

<script>
import db from "@/db"
export default {
  data() {
    return {
      load_pos_table:false,
      merchant:{},
      headers:[
        {text:"Amount",value:"amount"},
        {text:"Transaction ID",value:"trxId"},
        {text:"Transaction Date",value:"trxDate"},
        {text:"Transaction Status",value:"trxStatus"},
        {text:"Device Name",value:"device_name"},
        {text:"Total",value:"total"},
      ],
      transactions:[]
    }
  },

  beforeMount() {
    let subtotal = 0;
    let count = 1;
    if(this.$route.params.id){
      db.collection("merchants").doc(this.$route.params.id).get().then(async(doc)=>{
        this.merchant = doc.data();
        db.collection("Transaction")
            .where("email","==",this.merchant.email)
            .get().then((trasnap)=>{
          trasnap.forEach(async (trx)=>{
            if(!Object.prototype.hasOwnProperty.call(trx.data(),"status")) {
              let trxdata = trx.data()
              trxdata.id = trx.id;
              trxdata.device_name = "N/A";
              let devices = await db.collection("poses").where("code","==",trxdata.device_id).get();
              // console.log(trxdata.device_id)
              if(devices.docs.length >0){
                trxdata.device_name = devices.docs[0].data().name;
              }
              if(trxdata.trxStatus=="Transaction Successful") {
                subtotal = subtotal + parseFloat(trxdata.amount)
              }
              trxdata.total = subtotal.toFixed(2);
              this.transactions.push(trxdata);
              // console.log(count+","+trasnap.docs.length)
              if(count == trasnap.docs.length){
                this.load_pos_table =true;
                this.transactions.push({"amount":"","trxId":"","trxDate":"","trxStatus":"SubTotal","total":subtotal.toFixed(2)});
              }
            }
            count++;
          });
        })
      })
    }else{
      this.$router.replace("/merchants");
    }

  }
}
</script>