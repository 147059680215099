<template>
  <div class="sticky-header header-section ">
    <div class="header-left">
      <!--notification menu end -->
      <div class="clearfix"> </div>
    </div>
    <div class="header-right">


      <div class="profile_details" style="padding-bottom: 2px;">
        <ul>
          <li class="dropdown profile_details_drop">
            <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
              <div class="profile_img">
                <span class="prfil-img"><img src="images/default_profile.png" alt=""> </span>
                <div class="user-name">
                  <p>{{ name }}</p>
                  <span>Administrator</span>
                </div>
                <i class="fa fa-angle-down lnr"></i>
                <i class="fa fa-angle-up lnr"></i>
                <div class="clearfix"></div>
              </div>
            </a>
            <ul class="dropdown-menu drp-mnu">
              <li>
                <router-link to="/profile">
                  <i class="fa fa-suitcase"></i> Profile
                </router-link>
              </li>
              <li>
                <a href="#" @click.prevent="logout()"><i class="fa fa-sign-out"></i> Logout</a> </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="clearfix"> </div>
    </div>
    <div class="clearfix"> </div>
  </div>
</template>
<script>
import config from "@/config";
export default {
  data(){
    return{
      name:config.getUser().username
    }
  },
  methods:{
    logout(){
      sessionStorage.removeItem("user");
      location.reload();
    }
  }
}
</script>