<template>
<div class="forms">
    <h2 class="title1">Pos</h2>
    <div class="form-grids row widget-shadow" data-example-id="basic-forms">
      <div class="form-title">
        <h4 v-if="!update">Add New Pos</h4>
        <h4 v-if="update">Update Pos</h4>
        <span class="text-danger" v-if="error">{{error}}</span>
        <span class="text-success" v-if="success">{{success}}</span>
      </div>
      <div class="form-body">
        <form method="post" @submit.prevent="saveMerchant" autocomplete="off">
          <div class="form-group col-md-6">
            <label for="name">Device Name</label>
            <input type="text" class="form-control" id="name" name="name" placeholder="Device name" v-model="pos.name">
            <span class="text-danger" v-if="$v.pos.name.$error">This field is required</span>
          </div>
          <div class="form-group col-md-6">
            <label for="code">Device Code</label>
            <input type="text" class="form-control" id="code" name="code" placeholder="Device code" v-model="pos.code">
            <span class="text-danger" v-if="$v.pos.code.$error">This field is required</span>
          </div>
          <div class="form-group col-md-6">
            <label for="location">Location ID</label>
            <input type="text" class="form-control" id="location" name="location" placeholder="Device location" v-model="pos.location">
            <span class="text-danger" v-if="$v.pos.location.$error">This field is required</span>
          </div>
          <div class="form-group col-md-6">
            <label for="imei_no">IMEI No.</label>
            <input type="text" class="form-control" id="imei_no" name="imei_no" placeholder="Device IMEI No." v-model="pos.imei_no">
            <span class="text-danger" v-if="$v.pos.imei_no.$error">This field is required</span>
          </div>
          <div class="form-group col-md-6" v-if="!update">
            <label for="status">Status</label>
            <select class="form-control" id="status" name="status" v-model="pos.status">
              <option value="0">Deactive</option>
              <option value="1">Active</option>
            </select>
          </div>
          <div class="form-group col-md-12">
          <button type="submit" class="btn btn-default">Submit</button>
          </div>
        </form>
      </div>
    </div>
</div>
</template>
<script>
import db from '../db';
import {required} from "vuelidate/lib/validators";
export default {
  data(){
    return {
      update:false,
      success:"",
      error:"",
      pos:{
        name:'',
        code:"",
        imei_no:"",
        location:"",
        merchant_id:"",
        status:"1",
      }
    }
  },
  validations: {
    pos: {
      name:{required},
      code:{required},
      imei_no:{required},
      location:{required},
      status:{required}
    }
  },
  beforeMount() {
    if(this.$route.params.id) {
      this.update = true;
      let id = this.$route.params.id;
      db.collection("poses").doc(id).get().then((doc)=>{
        this.pos = doc.data();
      });
    }
  },
  methods:{
    saveMerchant(){
      this.$v.pos.$touch();
      if(this.$v.pos.$error) return
      if(!this.update) {
        db.collection("poses").add(this.pos).then(() => {
          this.success = "Data has been successfully saved.";
          this.$router.replace("/inventory-list");
        }).catch((error) => {
          this.error = error;
        });
      }else{
        db.collection("poses").doc(this.$route.params.id).update(this.pos).then(() => {
          this.success = "Data has been successfully updated.";
          this.$router.replace("/inventory-list");
        }).catch((error) => {
          this.error = error;
        });
      }
    }
  }
}
</script>