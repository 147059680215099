<template>
<div class="row">
  <div class="tables">
    <h2 class="title1">Pos List</h2>
    <div class="panel-body widget-shadow">
      <div class="row text-right">
        <router-link to="/inventory" class="btn btn-success">
          Add New Pos
        </router-link>
      </div>
      <table class="table datatable">
        <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Merchant Name</th>
          <th>Code</th>
          <th>Location</th>
          <th>IMEI No.</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(pos, index) in poses" :key="index">
          <th scope="row">{{ (index+1)}}</th>
          <td>{{ pos.name }}</td>
          <td>{{pos.merchant_name}}</td>
          <td>{{ pos.code }}</td>
          <td>{{ pos.location }}</td>
          <td>{{ pos.imei_no }}</td>
          <td>{{ status[pos.status] }}</td>
          <td>
            <router-link :to="{name:'Inventory_edit', params:{id:pos.id}}" class="btn btn-sm btn-success" title="Edit Inventory">
              <i class="fa fa-pencil"></i>
            </router-link>
            &nbsp;
            <button v-if="pos.status=='1'" class="btn btn-sm btn-danger ml-5" @click="deleteMerchant(pos,index)" title="Delete Merchant"><i class="fa fa-trash"></i> </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</template>

<script>
import db from '../db';
import $ from "jquery";
export default {
  name: "Inventory",
  data(){
    return {
      status:["","Unused","Used"],
      poses : {}
    }
  },
  beforeMount() {
    db.collection("poses").get().then((snapshort)=>{
       this.poses = snapshort.docs.map((doc)=>{
         let data = doc.data();
         data.id = doc.id;
         data.merchant_name ="";
         let merchant_id = data.merchant_id || '';
         if(merchant_id) {
           db.collection("merchants").doc(merchant_id).get().then((res) => {
             if(res.data()) {
               data.merchant_name = res.data().username;
             }
           })
         }
         return data;
       });
    }).catch((error) => {
      console.log("Error getting documents: ", error);
    });
  },
  methods:{
    deleteMerchant(merchant,index){
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          db.collection("poses").doc(merchant.id).delete().then(()=>{
            this.$swal.fire('Pos has been deleted', '', 'success');
                this.poses.splice(index,1);
          })
        }
      });
    }
  },
  mounted() {
    setTimeout(()=>{
      $(".datatable").DataTable({
        pagingType: 'full_numbers',
        pageLength: 10
      });
    },2000);
  }
}
</script>