<template>
<div class="row">
  <my-table v-if="merchant_list" page_title="Merchant List"
            :headers="headers"
            :items="merchant_list"
            :tableaction="true"
            show_add_new="/merchant"
            view_on_column="username"
            @view="viewMerchant"
            @delete="deleteMerchant"
            @edit="editMerchant"></my-table>
</div>
</template>

<script>
import db from '../db';
export default {
  name: "Merchant",
  data(){
    return {
      status:["Deactive","Active"],
      headers:[
        {text:"Name", value:"username"},
        {text:"Email", value:"email"},
        {text:"Mobile", value:"phone"},
        {text:"Status", value:"status"},
      ],
      merchant_list : []
    }
  },
  beforeMount() {
    db.collection("merchants").where("isAdmin","==",false).get().then((snapshort)=>{
       snapshort.docs.map((doc)=>{
         let data = doc.data();
         data.id = doc.id;
         data.status = data.status==1?'Activated':"Deactivated";
         this.merchant_list.push(data);
       });
    }).catch((error) => {
      console.log("Error getting documents: ", error);
    });
  },
  methods:{
    viewMerchant(merchant){
      this.$router.replace({name:'Merchant_view',params:{"id":merchant.id}});
    },
    editMerchant(merchant){
      this.$router.replace({name:'Merchant_edit',params:{"id":merchant.id}});
    },
    deleteMerchant(merchant,index){
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          db.collection("merchants").doc(merchant.id).delete().then(()=>{
            merchant.deviceIds.map((md)=> {
              db.collection("poses").doc(md.device_id).update({"status":"1","merchant_id":merchant.id}).then(()=> {
                this.$swal.fire('Merchant has been deleted', '', 'success');
                this.merchant_list.splice(index,1);
              });
            });
          })
        }
      });
    }
  }
}
</script>