<template>
  <div class="row">
    <my-table v-if="transactions"
              :tableaction="true"
              page_title="Reverse Transaction"
              :headers="headers"
              :items="transactions"
              :overwrite_default_action="true"
              :buttons="true"
    >
      <template slot="action" slot-scope="{item,index}">
        <button class="btn btn-warning" v-if="item.status=='Pending'" @click.prevent="approveReverse(item,index)"><i class="fa fa-check"></i> </button>
        <button class="btn btn-secondary" v-if="item.status =='Pending'" @click.prevent="declineReverse(item,index)"><i class="fa fa-times"></i> </button>
      </template>
    </my-table>
  </div>
</template>

<script>
import db from "@/db";
export default {
  data(){
    return {
      headers:[
        {text:"Merchant Name",value:"username"},
        {text:"Email",value:"email"},
        {text:"Amount",value:"amount"},
        {text:"Transaction ID",value:"trxId"},
        {text:"Transaction Date",value:"trxDate"},
        {text:"Status",value:"status"},
      ],
      transactions:[]
    }
  },
  beforeMount() {
    db.collection("Transaction").where("status","in",["reverse","reverse_done"]).get().then((trasnap)=>{
      trasnap.forEach((t)=>{
        let data = t.data();
        data.id = t.id;
        data.status = data.status == "reverse" ? "Pending" : data.status == "declined" ? "Declined" : "Reverse Done";
        data.username ='';
        db.collection("merchants").where("email","==",data.email).get().then((mersnap)=>{
          mersnap.forEach((mer)=> {
            let tt = mer.data();
            if(mer.data()) {
              data.username = tt.username;
            }
          })
        })
        this.transactions.push(data);
      });
    })
  },
  methods:{
    approveReverse(item,index){
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reverse it!'
      }).then((result) => {
        if (result.isConfirmed) {
          db.collection("Transaction").doc(item.id).update({"status":"reverse_done"}).then(()=> {
            this.$swal.fire('Reversal Done', '', 'success');
            this.transactions[index].status ="Reverse Done";
          })
        }
      });
    },
    declineReverse(item,index){
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, decline it!'
      }).then((result) => {
        if (result.isConfirmed) {
          db.collection("Transaction").doc(item.id).update({"status":"declined"}).then(()=> {
            this.$swal.fire('Decline Done', '', 'success');
            this.transactions[index].status ="Declined";
          })
        }
      });
    }
  }
}
</script>